import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LandingPage from "./components/LandingPage";


function App() {
  const externalJS = "../../../public/js/navbar.js";
  const script = document.createElement("script");
  script.src = externalJS;
  document.body.appendChild(script);
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
