import React, { useState, useEffect } from "react";
import "./files/abc.css";
import "./files/work.js";
import $ from "jquery";
import workInfo from "./files/workInfo";
import image from "./files/demo.png";
const Work = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isHD, setIsHD] = useState(window.innerWidth > 1500);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 992;
        const isHd = window.innerWidth > 1880;
        if (ismobile !== isMobile) setIsMobile(ismobile);
        if (isHd !== isHD) setIsHD(isHd);
      },
      false
    );
  }, [isMobile, isHD]);
  return (
    <section id="work">
      <div className="open-menu">
        <div className="work-container ">
          <div className="work-gallery ">
            <div className="grid-rows-2">
              <div className="container ">
                <div className="center-div">
                  <h1 className="gallery-title text-white">Works</h1>

                  <div className="row ">
                    <div
                      // className={`${
                      //   isHD
                      //     ? "col-lg-3 col-md-6 my-2 img-div"
                      //     : "col-lg-3 col-md-6 my-2 "
                      // }`}
                      className={`${
                        isHD
                          ? "grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4  img-div"
                          : "grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4 "
                      }`}
                    >
                      {workInfo.map((item) => {
                        return (
                          <div className="p-0.5">
                            <a
                              href="https://unsplash.it/1200/768.jpg?image=251"
                              data-toggle="lightbox"
                              data-gallery="example-gallery"
                              className="  example-gallery-item "
                            >
                              <figure class="snip1256 ">
                                <img
                                  src={item.image}
                                  className="img-fluid img-center responsive w-2"
                                  alt="..."
                                ></img>
                                <figcaption>
                                  {/* <h3>{item.title}</h3> */}
                                  <p>{item.shortDes}</p>
                                  <a href="#" class="read-more">
                                    Read More
                                  </a>
                                </figcaption>
                              </figure>
                              <h5>{item.title}</h5>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    isMobile
                      ? "d-flex justify-content-around btn-seeMore"
                      : "d-flex justify-content-end btn-seeMore"
                  }`}
                >
                  <a className="btn-main" href="#">
                    See More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
