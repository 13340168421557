import img1 from "../../../assets/img/works/demo.png";
const workInfo = [
  {
    id: 1,
    category: "Animation",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Explainer Video",
    shortDes:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
  {
    id: 2,
    category: "Python",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Python Web Scrapping",
    shortDes:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
  {
    id: 3,
    category: "Animation",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Autodesk Maya",
    shortDes:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
  {
    id: 4,
    category: "Javascript",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Vanilla Javascript Website",
    shortDes:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
  {
    id: 5,
    category: "React Js",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Getting Started With React",
    shortDes:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
  {
    id: 6,
    category: "React Js",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Vanilla Javascript Website",
    shortDes:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
  {
    id: 7,
    category: "Test Port",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Vanilla Javascript Website",
    shortDes:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
  {
    id: 8,
    category: "Test Port",
    link1: "www.youtube.com",
    link2: "www.github.com",
    icon1: "G",
    icon2: "Y",
    image: img1,
    url: "https://www.youtube.com/watch?v=G9RAjvus4AY",
    title: "Vanilla Javascript Website",
    shortDes:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......",
    Des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos......jshfgihsdfhgvsdhfogihen0bu eyoirgndflkghv",
  },
];

export default workInfo;
