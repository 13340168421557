import React from "react";
import Player from "react-player";
import homeVideo from "../../assets/videos/Home - Motion World.mp4";
import Navbar from "../navbar/Navbar";

const Home = () => {
  return (
    <>
      <section id="home" className="home">
        <div className="open-menu">
          <div className="home-container">
            {/* <div className="player-wrapper">
             <Player
              className="react-player "
              url={homeVideo}
              playing={true}
              controls={true}
              loop={true}
              muted={false}
              width="100%"
              height="100%"
            />
           
          </div> */}
            <video src={homeVideo} muted loop autoPlay></video>
            {/* <div class="overlay"></div> */}
            <div class="text">
              <h2>Motion World </h2>
              <h3>Aspiring Digital Advertising Agency</h3>
              <p>
                Are you looking for guide to communicating with your online
                audience? We are here to tell your story.
              </p>
              <a> Join Us</a>

              <a className="btn-contact" href="#">
                Contact
              </a>
            </div>

            <ul className="social">
              <li>
                <a
                  className="facebook"
                  href="https://www.facebook.com/motionworld.com.bd/"
                >
                  <i className="icon-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  className="instagram"
                  href="https://www.instagram.com/motionworld.com.bd/"
                >
                  <i className="icon-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  className="linkedin"
                  href="https://www.instagram.com/motionworld.com.bd/"
                >
                  <i className="icon-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  className="youtube"
                  href="https://www.youtube.com/channel/UC-_XZ-_XZ-_XZ-_XZ-_XZ-_XZ-"
                >
                  <i className="icon-youtube-play"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
