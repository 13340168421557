import React from "react";
import Navbar from "./navbar/Navbar";
import Home from "./sections/Home";
import Works from "./sections/Works";

import Work from "./sections/Work";
const LandingPage = () => {
  return (
    <>
      <div className="landing-page-container">
        <Navbar />
        <Home />
        <Work />
      </div>
    </>
  );
};

export default LandingPage;
