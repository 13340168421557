import React, { useState, useEffect } from "react";
import "../../assets/fonts/icomoon/style.css";
import $ from "jquery";
import "./navbar.css";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isOpen, setIsOpen] = useState("true");

  const hideModal = (event) => {
    event.preventDefault();
    console.log("event");
    document.body.classList.remove("offcanvas-menu");
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <div className="fixed-top scrolled-nav shadow  fixed z-10 w-full">
      <div class="site-mobile-menu site-navbar-target z-20">
        <div class="site-mobile-menu-header ">
          <div class="site-mobile-menu-close mt-3 ">
            <span class="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div class="site-mobile-menu-body"></div>
      </div>

      <header
        className={
          isScrolling
            ? "site-navbar site-navbar-target nav-background flex justify-center scrolling "
            : "site-navbar site-navbar-target nav-background  flex justify-center"
        }
        role="banner"
      >
        <div class="container md:flex  ">
          <div class="row align-items-center justify-between  mx-auto flex ">
            <div class="hidden md:block grid-col-4">
              <nav
                class="site-navigation text-right ml-auto "
                role="navigation"
              >
                <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block ">
                  <li>
                    <a
                      href="#home"
                      className="nav-link"
                      smooth={true}
                      onCLick={hideModal}
                    >
                      Home
                    </a>
                  </li>

                  <li>
                    <a href="#work" className="nav-link" smooth={true}>
                      Services
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="grid-col-4 text-center ">
              <div class="site-logo">
                <a href="/">
                  <img
                    src="https://raw.githubusercontent.com/HasanJahidul/motionworld.com/master/src/img/logo.png"
                    alt=""
                  />
                </a>
              </div>

              <div class="ml-auto toggle-button d-inline-block md:hidden fixed top-8 pr-12 ">
                <a className="site-menu-toggle  js-menu-toggle text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div class="hidden md:block grid-col-4">
              <nav class="site-navigation text-left mr-auto " role="navigation">
                <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                  <li>
                    <a href="about.html" class="nav-link">
                      About
                    </a>
                  </li>

                  <li>
                    <a href="contact.html" class="nav-link">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
